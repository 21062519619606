<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">Tags</h6>
      </b-row>
    </b-card>
    <div>
      <b-form ref="form" class="repeater-form">
        <!-- Row Loop -->
        <div v-for="(item, index) in items" :id="item.id" :key="index + '-' + item.id" ref="row" class="pb-2">
          <b-card>
            <b-row>
              <b-col md="8">
                <b-row>
                  <!-- Tag Name -->
                  <b-col md="12">
                    <b-form-group label="Tag Name" label-for="tag-name">
                      <b-form-input id="tag-name" type="text" v-model="item.title" placeholder="Tag Name" />
                    </b-form-group>
                  </b-col>
                  <!-- Tag Content -->
                  <b-col md="12">
                    <b-form-group label="Description (optional)" label-for="tag-description">
                      <b-form-textarea id="tag-description" v-model="item.content" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="2" md="2" class="mb-50" v-if="item.id">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="saveOption(index, item.id)">
                      <feather-icon icon="BookIcon" class="mr-25" />
                      <span>Update</span>
                    </b-button>
                  </b-col>
                  <b-col lg="2" md="2" class="mb-50" v-else>
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="danger" class="mt-0 mt-md-2" block @click="createOption(index)">
                      <feather-icon icon="BookIcon" class="mr-25" />
                      <span>Insert</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                <b-form-group :label="'Assigned Products'" label-for="vat-rate-products_tagged" class="producttag"></b-form-group>
                <v-select id="vat-rate-product" v-model="item.products" multiple :options="productsOptions" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>

    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add New</span>
    </b-button>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BCard, BFormTextarea } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";

import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    BCard,
    BButton,
    vSelect,
    BFormGroup,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      tags_raw: [],
      products_raw: [],
      productsOptions: [],
    };
  },

  watch: {
    items: {
      deep: true,
      handler(val) {
        this.items = val;
        this.$forceUpdate();
      },
    },
  },
  methods: {
    async createOption(index) {

      await useJwt
        .post_tags('create', {
          title: this.items[index].title,
          content: this.items[index].content,
          amount: this.items[index].amount
        })
        .then(async () => {

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Tag created`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully created tag.`,
            },
          });


          await useJwt
            .get_tags()
            .then((response) => {
              localStorage.setItem(
                "userTags",
                JSON.stringify(response.data.data)
              );
              this.tags_raw = response.data.data
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't downlad your tags data from the server.`,
                },
              });
            });

          if (this.tags_raw.length) {
            this.items = []
            let item = {};
            for (let x = 0; x < this.tags_raw.length; x++) {
              item.id = this.tags_raw[x].id;
              item.title = this.tags_raw[x].title;
              item.amount = this.tags_raw[x].amount;
              item.content = this.tags_raw[x].content;
              item.products = [];
              this.items.push(item);
              item = {};
            }
          }

        })
        .catch((e) => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't create new tags on the server.`,
            },
          });
        });
    },
    repeateAgain() {
      this.items.push({})
    },
    async saveOption(index, id) {
      await useJwt
        .patch_tag(id, {
          title: this.items[index].title,
          content: this.items[index].content,
        })
        .then(async (response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Tag saved`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully saved tag.`,
            },
          });
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't create new tag on the server.`,
            },
          });
        });

      await useJwt
        .delete_tag_product(id)
        .then(() => {
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't update product data on the server.`,
            },
          });
        });
      if (this.items[index].products.length) {
        for (let x = 0; x < this.items[index].products.length; x++) {
          await useJwt
            .post_product_tag("create", {
              product_id: this.items[index].products[x].value,
              tag_id: id,
            })
            .then(() => {
              this.$forceUpdate();
            });
        }
      }
    },
  },
  async beforeCreate() {
    await useJwt
      .list_products()
      .then((response) => {
        localStorage.setItem("userProducts", JSON.stringify(response.data.data));
        this.products_raw = response.data.data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your tags data from the server.`,
          },
        });
      });

    await useJwt
      .get_tags()
      .then((response) => {
        localStorage.setItem("userTags", JSON.stringify(response.data.data));
        this.tags_raw = response.data.data;
      })
      .catch((e) => {
        console.log(e);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your tags data from the server.`,
          },
        });
      });

    for (let y = 0; y < this.products_raw.length; y++) {
      if (this.products_raw[y].is_deleted == 0) {
        this.productsOptions.push({
          value: this.products_raw[y].id,
          label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title,
        });
      }
    }
    let tag_temp;

    if (this.tags_raw.length) {
      let item = {};
      for (let x = 0; x < this.tags_raw.length; x++) {

        item.id = this.tags_raw[x].id;
        item.title = this.tags_raw[x].title;
        item.content = this.tags_raw[x].content;
        item.products = [];

        for (let y = 0; y < this.products_raw.length; y++) {
          console.log(this.products_raw[y])
          if (this.products_raw[y].tags) {
            tag_temp = this.products_raw[y].tags.split(",");
            tag_temp = [...new Set(tag_temp)];
            if (tag_temp.length) {
              for (let z = 0; z < tag_temp.length; z++) {
                if (tag_temp[z] == this.tags_raw[x].id) {
                  item.products.push({
                    value: this.products_raw[y].id,
                    label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title,
                  });
                }
              }
            }
          }
        }
        this.items.push(item);
        item = {};
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
